<template>
  <v-btn color="primary" :loading="isSubmitted" block outlined @click="onGoogleSignIn">
    <v-avatar size="25" class="mr-2">
      <v-img :src="googleLogoSrc" alt="" max-height="20px" width="auto" contain></v-img>
    </v-avatar>
    {{ title }}
  </v-btn>
</template>

<script>
import { defineComponent } from 'vue';
import { useGoogleSignInPopup } from '@/features/access/services/authenticationService';
import { internalApiRequest } from '@/shared/services/apiService';

export default defineComponent({
  name: 'GoogleSignInButton',
  props: {
    title: {
      type: String,
      default: 'Google',
    },
  },
  data() {
    return {
      submitted: false,
    };
  },
  computed: {
    isSubmitted() {
      return this.submitted;
    },
    googleLogoSrc() {
      return require('@/features/access/assets/google_sign_in_logo.svg');
    },
  },
  methods: {
    onGoogleSignIn() {
      this.submitted = true;

      useGoogleSignInPopup()
        .then(async (resp) => {
          internalApiRequest({
            method: 'POST',
            path: `api/v1/google-login`,
            data: {
              tokenId: resp?.token,
              email: resp?.user?.email,
              firstname: resp?.user?.displayName?.split(' ').slice(0, -1)?.join(' '),
              lastname: resp?.user?.displayName?.split(' ').slice(-1)?.join(' '),
            },
          })
            .then((resp) => {
              localStorage.setItem('token', resp?.access_token);

              this.$router.push({
                name: 'dashboard',
              });
            })
            .catch(() => {
              this.$notification.error(this.$t('verification.failed'));
            });
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
